import PDFMerger from 'packages/pdf-merger';

import JiraWidget from './jira-widget';
import SentryCatch from './sentry-catch';

const publishStudy = async (studyId, traditional = false) => {
  const args = traditional ? '?traditional=1' : '';
  const url = `${window.PRENUVOCONFIG.Routes.HI}/api/v1/study/${studyId}/publish${args}`;
  const resp = await fetch(url, { credentials: 'include' });
  if (resp.status === 200) {
    alert(`Study ${studyId} has been sent to publish queue`);
  } else {
    alert(`Failed to send ${studyId} to publish queue`);
  }
};

(function bindToWindow() {
  window.PDFMerger = PDFMerger;
  window.JiraWidget = JiraWidget;
  window.Sentry = SentryCatch;
  window.publishStudy = publishStudy;
})();
