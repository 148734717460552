class JiraWidget {
  constructor(userData) {
    this.userData = userData;
    this.postIssue = this.postIssue.bind(this);
    this._sendJiraIssue = this._sendJiraIssue.bind(this);
  }

  async _sendJiraIssue(postData) {
    const res = await fetch(`/admin/create-issue`, {
      method: 'POST',
      body: JSON.stringify(postData),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('posting to Jira');

    if (res.status === 200) {
      console.log('posted successfully');
      return res.status;
    } else {
      throw new Error(`Error: ${res.status} response sending to Jira.`);
      return false;
    }
  }

  async postIssue(data) {
    const dataObj = data.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {});

    const postData = {
      fields: {
        project: {
          key: 'TRIAGE',
        },
        summary: dataObj.title,
        description: `${dataObj.description}\n\nLink:\n${window.location.href}\n\nSubmitted by:\n${userData.name}\n${userData.email}\n${userData.prenuvoID}`,
        issuetype: {
          name: 'Bug',
        },
      },
    };

    return await this._sendJiraIssue(postData);
  }
}

export default JiraWidget;
