import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import pdfjsLib from 'pdfjs-dist';
import JSZip from 'jszip';

const formatTemplateObj = (studyID, sku, userIDs) => ({
  'medical-referral': {
    pdfLink: `/admin/study/${studyID}/medical_referral`,
    template: [
      {
        fields: {
          firstname: [{ page: 0, x: 35, y: 700, font: 'Helvetica', size: 12 }],
          lastname: [{ page: 0, x: 35, y: 670, font: 'Helvetica', size: 12 }],
          dob: [{ page: 0, x: 35, y: 607, font: 'Helvetica', size: 12 }],
          gender: [{ page: 0, x: 35, y: 567, font: 'Helvetica', size: 12 }],
        },
      },
      {
        fields: {
          fullname: [{ page: 0, x: 35, y: 535, font: 'Helvetica', size: 12 }],
          phone_number: [{ page: 0, x: 35, y: 505, font: 'Helvetica', size: 12 }],
          email: [{ page: 0, x: 35, y: 475, font: 'Helvetica', size: 12 }],
          reason: [{ page: 0, x: 35, y: 375, font: 'Helvetica', size: 12 }],
          designation: [{ page: 0, x: 35, y: 442, font: 'Helvetica', size: 12 }],
        },
      },
    ],
  },
  'referral-package': {
    pdfLink: `/admin/study/${studyID}/referral-page`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 100, y: 660, font: 'Helvetica', size: 12 }],
          address: [{ page: 0, x: 100, y: 647, font: 'Helvetica', size: 12 }],
          cityState: [{ page: 0, x: 100, y: 634, font: 'Helvetica', size: 12 }],
          country: [{ page: 0, x: 100, y: 621, font: 'Helvetica', size: 12 }],
          postalCode: [{ page: 0, x: 100, y: 608, font: 'Helvetica', size: 12 }],
          salutation: [
            { page: 1, x: 160, y: 515, font: 'HelveticaBold', size: 12, color: [0.4, 0.4, 0.4] },
          ],
          referralCode: [
            { page: 2, x: 273, y: 77, font: 'HelveticaBold', size: 30 },
            { page: 3, x: 268, y: 505, font: 'Helvetica', size: 16 },
            { page: 4, x: 230, y: 100, font: 'HelveticaBold', size: 30 },
            { page: 5, x: 60, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
            { page: 5, x: 148, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
            { page: 5, x: 232, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
            { page: 5, x: 323, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
            { page: 5, x: 408, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
            { page: 5, x: 500, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
            { page: 5, x: 580, y: 57, font: 'HelveticaBold', size: 18, rotate: degrees(90) },
          ],
        },
      },
    ],
  },
  invoice: {
    pdfLink: `/admin/study/${studyID}/invoice/embed?raw=true`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 550, y: 698, font: 'Quicksand', size: 9 }],
        },
      },
    ],
  },
  'detailed-invoice': {
    pdfLink: `/admin/study/${studyID}/invoice/embed?raw=true`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 550, y: 703, font: 'Quicksand', size: 10 }],
        },
      },
      userIDs.length > 1
        ? {
            fields: {
              fullname: [{ page: 0, x: 550, y: 648, font: 'Quicksand', size: 10 }],
              msp_prescriber: [{ page: 0, x: 550, y: 622, font: 'Quicksand', size: 10 }],
            },
          }
        : { fields: {} },
    ],
  },
  'report-admin': {
    pdfLink: `/admin/study/${studyID}/latest-pdf-report`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 43, y: 770, font: 'HelveticaBold', size: 20 }],
          dob: [{ page: 0, x: 430, y: 671, font: 'Helvetica', size: 12 }],
          gender: [{ page: 0, x: 37, y: 671, font: 'Helvetica', size: 12 }],
        },
      },
    ],
  },
  'report-rad': {
    pdfLink: `/radiologist/study/${studyID}/latest-pdf-report`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 43, y: 770, font: 'HelveticaBold', size: 20 }],
          dob: [{ page: 0, x: 430, y: 671, font: 'Helvetica', size: 12 }],
          gender: [{ page: 0, x: 37, y: 671, font: 'Helvetica', size: 12 }],
        },
      },
    ],
  },
  'report-rad-structured-deidentified': {
    pdfLink: `/radiologist/study/${studyID}/latest-pdf-report`,
    template: [
      {
        fields: {
          fake_name: [{ page: 0, x: 425, y: 770, font: 'HelveticaBold', size: 12 }],
          identifier: [{ page: 0, x: 43, y: 770, font: 'HelveticaBold', size: 12 }],
          fake_dob: [{ page: 0, x: 425, y: 675, font: 'Helvetica', size: 12 }],
          gender: [{ page: 0, x: 45, y: 675, font: 'Helvetica', size: 12 }],
        },
      },
    ],
  },
  'report-rad-traditional-deidentified': {
    pdfLink: `/radiologist/study/${studyID}/latest-pdf-report`,
    template: [
      {
        fields: {
          fake_name: [{ page: 0, x: 150, y: 593, font: 'Quicksand', size: 10 }],
          identifier: [{ page: 0, x: 150, y: 623, font: 'Quicksand', size: 10 }],
          fake_dob: [{ page: 0, x: 150, y: 578, font: 'Quicksand', size: 10 }],
          gender: [{ page: 0, x: 150, y: 563, font: 'Quicksand', size: 10 }],
        },
      },
    ],
  },
  'report-user': {
    pdfLink: `/user/pdf/${studyID}/readingPublished-1`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 43, y: 770, font: 'HelveticaBold', size: 20 }],
          dob: [{ page: 0, x: 429, y: 671, font: 'Helvetica', size: 12 }],
          gender: [{ page: 0, x: 36, y: 671, font: 'Helvetica', size: 12 }],
        },
      },
    ],
  },
  'report-traditional': {
    pdfLink: `/api/user/pdf/${studyID}/traditional_report`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 150, y: 593, font: 'HelveticaBold', size: 12 }],
          dob: [{ page: 0, x: 150, y: 578, font: 'Helvetica', size: 10 }],
          gender: [{ page: 0, x: 150, y: 563, font: 'Helvetica', size: 10 }],
        },
      },
    ],
  },
  'history-admin': {
    pdfLink: `/admin/study/${studyID}/study-info/pdf-rendered`,
    template: [
      {
        fields: {
          dob: [{ page: 0, x: 163, y: 613, font: 'Helvetica', size: 12 }],
          gender: [{ page: 0, x: 28, y: 613, font: 'Helvetica', size: 12 }],
        },
      },
    ],
  },
  'consent-admin': {
    pdfLink: `/admin/study/${studyID}/consent/pdf-rendered`,
    template: [
      {
        fields: {
          fullname: [{ page: 0, x: 35, y: 775, font: 'Helvetica', size: 16 }],
          dob: [{ page: 0, x: 450, y: 775, font: 'Helvetica', size: 16 }],
          gender: [{ page: 0, x: 375, y: 775, font: 'Helvetica', size: 16 }],
        },
      },
    ],
  },
  'patient-consent': {
    pdfLink: `/api/v1/consent/${studyID}/download`,
    template: [],
  },
});

class PDFMerger {
  constructor(template, prenuvoIDs, studyID = '', sku = null, referrerInfo = null) {
    this.template = template;
    this.prenuvoIDs = prenuvoIDs;
    this.studyID = studyID;
    this.sku = sku;
    this.referrerInfo = referrerInfo;
    this.pdfDoc = {};

    this.fetchTemplate = this.fetchTemplate.bind(this);
    this.fetchPDF = this.fetchPdf.bind(this);
    this.fetchPii = this.fetchPii.bind(this);
    this.appendPdf = this.appendPdf.bind(this);
    this.renderPDF = this.renderPDF.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.updateRawPdf = this.updateRawPdf.bind(this);
    this.downloadRawPdf = this.downloadRawPdf.bind(this);
    this.downloadPdfAsJpg = this.downloadPdfAsJpg.bind(this);
    this.getBase64 = this.getBase64.bind(this);
  }

  fetchTemplate(template, studyID, prenuvoIDs) {
    const templateObj = formatTemplateObj(studyID, this.sku, [
      ...prenuvoIDs,
      this.referrerInfo?.prenuvoId,
    ]);
    return templateObj[template];
  }

  async fetchPdf(url, invoice = false) {
    const hiUrl = !invoice ? `${window.PRENUVOCONFIG.Routes.HI}${url}` : url;

    const resp = await fetch(hiUrl, {
      method: 'GET',
      credentials: 'include',
    });

    if (resp.status === 200) {
      const buffer = await resp.arrayBuffer();
      return new Uint8Array(buffer);
    }
    throw new Error('error fetching pdf');
  }

  async fetchReason(studyID) {
    const url = `${window.PRENUVOCONFIG.Routes.HI}/admin/study/${studyID}/reasonForScan?json=true`;
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    return resp.json();
  }

  async fetchPii(prenuvoIDs, refCode = false) {
    const url = `${window.PRENUVOCONFIG.Routes.PII}/api/v1/userV2/?user_id=${prenuvoIDs.join(',')}`;
    const reqObj = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    const resp = await fetch(url, reqObj);
    const data = await resp.json();

    if (refCode) {
      const refUrl = '/admin/refCode';
      const refResp = await fetch(refUrl, {
        method: 'POST',
        body: JSON.stringify({ prenuvoID: prenuvoIDs }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const refData = await refResp.json();

      if (refResp.status === 200) {
        data[0].referralCode = refData.refCode;
        data[0].cityState = `${data[0].city}, ${data[0].state}`;
        data[0].salutation = `Dear ${data[0].firstname},`;
      }
    }

    if (resp.status === 200) {
      return data;
    }
    console.error('error fetching pii');
    return '';
  }

  convertSpecialCharactersToEnglishDictionary(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  async appendPdf(pages, personalInfo, writeMap = null) {
    const pageIndex = writeMap.page;
    const page = pages[pageIndex];

    const { x, y, size, font = 'Helvetica', rotate = degrees(0) } = writeMap;
    let { color = [0, 0, 0] } = writeMap;
    let pdfFont;
    if (this.template === 'invoice' || this.template === 'detailed-invoice') {
      color = [0.29, 0.29, 0.29];
    }
    if (font === 'Quicksand') {
      const { host } = window.location;
      // eslint-disable-next-line no-nested-ternary
      const environment = host.includes('staging.prenuvo')
        ? '-staging'
        : host.includes('www.prenuvo')
        ? ''
        : '-dev';
      const url = `https://prenuvo-apps-cloudfront-assets${environment}.s3.ca-central-1.amazonaws.com/fonts/Quicksand-Regular.ttf`;
      const fontBytes = await fetch(url).then((res) => res.arrayBuffer());
      await this.pdfDoc.registerFontkit(fontkit);
      pdfFont = await this.pdfDoc.embedFont(fontBytes);
    } else {
      pdfFont = await this.pdfDoc.embedFont(StandardFonts[font]);
    }

    const convertedPersonalInfo = this.convertSpecialCharactersToEnglishDictionary(personalInfo);

    page.drawText(convertedPersonalInfo, {
      x,
      y,
      size,
      font: pdfFont,
      color: rgb(...color),
      rotate,
    });
  }

  getCenteredXPosition(maximum, textWidth) {
    const textWidthModifier = 1.05 / 2;
    const position = maximum / 2 - Math.round(textWidthModifier * textWidth);

    return position
  }

  getYPosition(key, position) {
    const isPatientScan = !['400', '350', '370'].includes(this.sku);
    const isInvoiceReferrer = this.template === 'detailed-invoice';

    switch (key) {
      case 'gender':
        return isPatientScan ? position + 13 : position;
      case 'dob':
        return isPatientScan ? position + 13 : position;
      case 'fullname':
        return isInvoiceReferrer ? position + 6 : position;
      default:
        return position;
    }
  }

  handlePatientHeaderInformation(key, pages, headerContent) {
    const textWidth = headerContent?.length;
    const maximum = textWidth > 25 ? 880 : textWidth > 20 ? 890 : 930;
    pages.forEach((page) => {
      // adding a white rectangle to ensure the old reports won't have the patientID on the header
      page.drawRectangle({ x: 350, y: 809, width: 250, height: 20, color: rgb(1, 1, 1) });

      const headerRenderMap = {
        page: pages.indexOf(page),
        x: this.getCenteredXPosition(maximum, headerContent?.length),
        y: this.getYPosition(key, 811),
        font: 'Helvetica',
        size: 10,
      };
      this.appendPdf(pages, headerContent, headerRenderMap);
    });
  }

  async renderPDF(url = false, inputPdf = false, base64 = false) {
    const templateData = await this.fetchTemplate(this.template, this.studyID, this.prenuvoIDs);
    const userIDs = this.prenuvoIDs;
    const { template } = templateData;

    let pdfLink = url || templateData.pdfLink;
    let personalInfo = await this.fetchPii(userIDs, this.template === 'referral-package');

    if (this.template === 'detailed-invoice') {
      pdfLink += `&sex=${personalInfo[0].gender}`;
    }

    const pdfBytes =
      inputPdf ||
      (await this.fetchPdf(
        pdfLink,
        this.template === 'invoice' ||
          this.template === 'detailed-invoice' ||
          this.template === 'referral-package' ||
          this.template === 'medical-referral',
      ));

    personalInfo = personalInfo.sort((a, b) => {
      if (userIDs.indexOf(a.user_id) < userIDs.indexOf(b.user_id)) {
        return -1;
      }

      return 1;
    });

    if (this.template === 'medical-referral') {
      const { reason } = await this.fetchReason(this.studyID);

      personalInfo.push({
        fullname: this?.referrerInfo?.fullName || '',
        phone_number: this?.referrerInfo?.phoneNumber || '',
        email: this?.referrerInfo?.emailAddress || '',
        reason,
        designation: this?.referrerInfo?.profession || '',
      });
    }

    if (this.template === 'invoice' || this.template === 'detailed-invoice') {
      personalInfo.push({
        fullname:
          this?.referrerInfo?.firstname && this?.referrerInfo?.lastname
            ? `${this?.referrerInfo?.firstname} ${this?.referrerInfo?.lastname}`
            : '',
        designation: this?.referrerInfo?.profession || '',
      });
    }

    this.pdfDoc = await PDFDocument.load(pdfBytes);

    const pages = this.pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();
    let count;
    const pdfFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica);

    for (const [i, entry] of template.entries()) {
      const fieldKeys = Object.keys(entry.fields);
      const PII = personalInfo[i];
      count = 0;

      for (const key of fieldKeys) {
        if (key === 'identifier') {
          PII.identifier = this.studyID.split('-').join('').substr(0, 16);
        }

        if (key === 'fake_name') {
          PII.fake_name = `${PII.fake_lastname}, ${PII.fake_firstname} (Alias)`;
        }

        if (
          key === 'fullname' &&
          this.template.includes('report') &&
          !this.template.includes('traditional')
        ) {
          const headerContent = `Patient: ${PII.lastname}, ${PII.firstname}`;
          this.handlePatientHeaderInformation(key, pages, headerContent);
        }

        if (key === 'fake_dob') {
          PII.fake_dob = `${PII.fake_dob} (Approx.)`;
        }

        count += 1;

        if (key === 'gender') {
          let sex = PII[key];

          sex = sex[0].toUpperCase() + sex.slice(1);
          PII[key] = sex;
        }

        for (const renderMap of entry.fields[key]) {
          const userInfo = PII[key] || '';
          if (
            (this.template === 'invoice' || this.template === 'detailed-invoice') &&
            (key === 'fullname' || key === 'msp_prescriber')
          ) {
            const textWidth = pdfFont.widthOfTextAtSize(userInfo, renderMap.size);
            renderMap.x -= Math.round(1.05 * textWidth);
          }

          await this.appendPdf(pages, userInfo, renderMap);
        }

        if (this.template === 'report-traditional') {
          for (const page of pages) {
            const renderMap = {
              page: pages.indexOf(page),
              x: count * 140,
              y: 800,
              font: 'Helvetica',
              size: 10,
            };

            await this.appendPdf(pages, PII[key], renderMap);
          }
        }

        if (
          this.studyID === '56c13c42-3bd9-46d6-9003-b50c33e47c23' &&
          this.template === 'report-user'
        ) {
          for (const page of pages) {
            const renderMap = {
              page: pages.indexOf(page),
              x: width / 2 - 200,
              y: height / 2 + 100,
              font: 'Helvetica',
              size: 160,
              rotate: degrees(-45),
              color: [0.95, 0.1, 0.1],
            };

            this.appendPdf(pages, 'DEMO', renderMap);
          }
        }
      }
    }

    return base64 ? this.pdfDoc.saveAsBase64({ dataUri: true }) : this.pdfDoc.save();
  }

  async handleDownload(url = false, callback = null) {
    const outputPdfBytes = await this.renderPDF(url);
    const blob = new Blob([outputPdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');

    link.target = '_blank';
    link.href = window.URL.createObjectURL(blob);
    link.download = `${this.template}-${this.studyID}.pdf`;
    link.click();

    if (callback != null) {
      callback();
    }
  }

  getBase64(url = false) {
    return this.renderPDF(url, false, true);
  }

  updateRawPdf(pdf) {
    return this.renderPDF(false, pdf);
  }

  async downloadPdfAsJpg(url = false) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

    const zip = new JSZip();
    const bytes = await this.renderPDF(url);
    const pdfBytes = new Uint8Array(bytes);
    const pdf = await pdfjsLib.getDocument(pdfBytes).promise;

    const pageUris = [];

    for (let i = 0; i < pdf.numPages; i += 1) {
      const page = pdf.getPage(i + 1);

      const viewport = page.getViewport({ scale: 2 });
      const canvas = document.createElement('canvas');

      canvas.display = 'none';
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const ctx = canvas.getContext('2d');

      page.render({ canvasContext: ctx, viewport });

      const uri = canvas.toDataURL('image/jpeg', 1.0);

      pageUris.push(uri);
      canvas.remove();
    }

    const imgs = zip.folder(`${this.template}-${this.studyID}`);

    for (const [i, data] of pageUris.entries()) {
      imgs.file(`${this.template}-${this.studyID}-page-${i + 1}.jpg`, data.split(',')[1], {
        base64: true,
      });
    }

    const blob = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');

    link.target = '_blank';
    link.href = window.URL.createObjectURL(blob);
    link.download = `${this.template}-${this.studyID}.zip`;
    link.click();
  }

  async downloadRawPdf(url = false) {
    const template = await this.fetchTemplate(this.template, this.studyID, this.prenuvoIDs);
    const pdfLink = url || template.pdfLink;
    const pdf = await this.fetchPdf(pdfLink, this.template === 'invoice');
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const link = document.createElement('a');

    link.target = '_blank';
    link.href = window.URL.createObjectURL(blob);
    link.download = `${this.template}-${this.studyID}.pdf`;
    link.click();
  }
}

export default PDFMerger;
