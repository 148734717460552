import * as Sentry from '@sentry/browser';

function mapDSN(server) {
  switch (server) {
    case 'apps':
      return 'https://911c21d64c484c9996bee7bce024d797@sentry.io/1318266';
    case 'hi':
      return 'https://b123dda4cdf640d48db623d8fd653e9d@sentry.io/1498273';
    case 'pii':
      return 'https://18aaa729bf2f4ea096d7cc53e8220da5@sentry.io/1318256';
    default:
      return 'https://911c21d64c484c9996bee7bce024d797@sentry.io/1318266';
  }
}

class SentryCatch {
  constructor(prenuvoID, server = '') {
    this.prenuvoID = prenuvoID;

    this.Sentry = Sentry;

    this.Sentry.init({ dsn: mapDSN(server) });

    this.Sentry.configureScope((scope) => {
      scope.setUser({ prenuvoID: this.prenuvoID });
    });

    this._catchWindowError = this._catchWindowError.bind(this);
    this.catchError = this.catchError.bind(this);

    window.onerror = this._catchWindowError;
    window.addEventListener('error', this._catchWindowError, { passive: true });
  }

  _catchWindowError(msg, url, line, col, err) {
    if (err) {
      this.Sentry.configureScope((scope) => {
        scope.setExtra('url', url);

        scope.setExtra('message', msg);

        scope.setExtra('location', `line ${line}, column ${col}`);
      });

      this.Sentry.captureException(err);

      console.log('error caught');

      console.error(`${msg} at ${line}, ${col}`);
    }

    return false;
  }

  catchError(err) {
    if (err) {
      this.Sentry.captureException(err);
    }
  }
}

export default SentryCatch;
